@font-face {
  font-family: "Akkurat";
  font-weight: 100;
  src: url("./assets/fonts/AkkuratLLWeb-Thin.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Thin.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 100;
  font-style: italic;
  font-family: "AkkuratLLWeb-ThinItalic";
  src: url("./assets/fonts/AkkuratLLWeb-ThinItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-ThinItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 300;
  src: url("./assets/fonts/AkkuratLLWeb-Light.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 300;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-LightItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-LightItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 400;
  src: url("./assets/fonts/AkkuratLLWeb-Regular.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 400;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-Italic.woff") format("woff"),url("./assets/fonts/AkkuratLLWeb-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 700;
  src: url("./assets/fonts/AkkuratLLWeb-Bold.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 700;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-BoldItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-BoldItalic.woff2") format("woff2");
}


@font-face {
  font-family: "Akkurat";
  font-weight: 900;
  src: url("./assets/fonts/AkkuratLLWeb-Black.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Black.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 900;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-BlackItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-BlackItalic.woff2") format("woff2");
}

* { 
  box-sizing: border-box; 
  -webkit-tap-highlight-color: transparent; 
}

html {
  height: -webkit-fill-available;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Akkurat', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: font-size .1s;
  overflow-x: hidden;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

a:focus-visible, input:focus-visible, select:focus-visible, textarea:focus-visible, button:focus-visible {
  outline-color: #397AB5 !important;
}

#root {
  height: 100vh;
  position: relative;
}

a, a:visited {
  color: #38669f;
}

p {
  margin-top: 0px;
  margin-bottom: 16px;
  line-height: 1.5em;
  letter-spacing: -5;
}

.leaflet-control-container {
  display: none;
}

a img {
  width: 100%;
}

img.loading {
  opacity: 0;
}

p:last-child {
  margin-bottom: 0;
}

.preview .loading {
  opacity: 1 !important;
}

.preview .previewdisable {
  pointer-events: none;
}

.mapcluster {
  background: #4A90E280;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  display: grid !important;
  align-content: center;
  justify-content: center;
  font-size: 1.2em;
}

.kpMarker {
  background: transparent url("./assets/marker_kp.png") no-repeat;
  background-size: 75%;
  background-position: center bottom;
}

.museumMarker {
  background: transparent url("./assets/marker_museum.png") no-repeat;
  background-size: 75%;
  background-position: center bottom;
}

.kpMarker.active {
  background: transparent url("./assets/marker_kp_active.png") no-repeat;
  background-size: 75%;
  background-position: center bottom;
}

.museumMarker.active {
  background: transparent url("./assets/marker_museum_active.png") no-repeat;
  background-size: 75%;
  background-position: center bottom;
}

.pswp__zoom-wrap {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.pswp {
  pointer-events: all;
}

.pswp__container {
  transition: transform 0.00001s;
}

.pswp__default-caption {
  max-height: 40vh;
  overflow: auto;
  text-align: left !important;
}

.pswp__caption__center {
  max-width: 90%;
}

.pswp__hide-on-close {
  opacity: 1 !important;
}

/* Lexical WYSIWYG editor */
.editor_textBold { 
  font-weight: bold; 
} 
.editor_textItalic { 
  font-style: italic; 
} 
.editor_textUnderline { 
  text-decoration: underline; 
} 
.editor_textStrikethrough { 
  text-decoration: line-through; 
}

.editor_nestedListItem {
  list-style-type: none;
}
.editor_nestedListItem:before,
.editor_nestedListItem:after {
  display: none;
}

/* Carousel */
.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  background-color: rgba(255, 255, 255, 0.02);
}

.carousel .control-prev.control-arrow {
  left: 15px !important;
}

.carousel .control-next.control-arrow {
  right: 15px !important;
}

.carousel .slider {
  display: flex;
  align-items: center;
  background: #333;
  border-radius: 4px;
}